import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import TwitterIcons from "@mui/icons-material/Twitter"
import PublicIcon from '@mui/icons-material/Public';
import YoutubeIcon from "@mui/icons-material/YouTube"
import IconButton from '@mui/material/IconButton'
import ReviewImg from "../images/review.png"
import Grid from "@mui/material/Grid"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const IndexPage = () => {
  return (
    <Layout>
    <SEO />

    <div style={{
      height: "48vh"
    }} className="bg-indigo-500 relative">
      <div className="flex justify-center m-auto items-center h-full absolute w-full">
        <div className="grid sm:grid-cols-1 md:grid-cols-4 gap-2">
          <div className="col-span-2">
            <h1 className="text-5xl md:text-7xl text-center md:text-left text-gray-900 dark:text-gray-50">魅力値７</h1>
          </div>
          <div className="col-span-2">
            <h3 className="text-center md:text-left my-2 md:m-0">気づいたら、<br className="hidden md:block"/>周りが皆、<br className="hidden md:block"/>大人。</h3>
          </div>
        </div>
      </div>
      <div className="absolute p-2 h-16 bottom-0 text-center w-full flex justify-center  ">
        <IconButton sx={{width: 48, mx: 2}} component={"a"} href="https://twitter.com/miryokuP7">
          <TwitterIcons />
        </IconButton>
        <IconButton sx={{width: 48, mx: 2}} component={"a"} href="https://youtube.com/channel/UCvrHDUs0Jmn0eqw0ZZa7mEQ">
          <YoutubeIcon />
        </IconButton>
      </div>
    </div>
    <div className=" mx-auto my-4 px-4 md:mx-12 lg:mx-24 mt-4">
      <div className="prose">
        <h2>誰？</h2>
        <p>
          なんにも考えていない大学生。<br />
        </p>

        <h2 className="">やってること</h2>
        <ul className="">
          <li>簡単なWebアプリ開発</li>
          <li>ピアノ</li>
          <li>DTMはじめました</li>
          <li>とか</li>
        </ul>

      </div>

      <div className="prose mt-12 mb-6">
        <h2 className="">作ったもの</h2>
      </div>

      <Grid container spacing={2} alignItems="stretch" sx={{
        width: "100% ",
        ml: -1
      }}>

        <Grid item  xs={12} sm={6}>
          <div className="card w-full bg-base-100 shadow-xl mx-auto  h-full">
          <figure className="">
            <iframe width="100%" src="https://www.youtube.com/embed/qhj3C-NhTx4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">物理学演習</h2>
            <p>この授業きらい。<br />数時間で作った、クオリティは低い。</p>
          </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className="card w-full bg-base-100 shadow-xl image-full mx-auto h-full">
            <figure><img src={ReviewImg} /></figure>
            <div className="card-body">
              <h2 className="card-title">埼大住民</h2>
              <p>メインは授業レビュー</p>
              <div className="card-actions justify-end">
                <a className="btn btn-primary" href="https://saidai-jumin.com">サイトへ</a>
              </div>
            </div>
          </div>
        </Grid>



      </Grid>

    </div>
    </Layout>
  )
}



export default IndexPage
